$(function() {

  // ページスクロール処理
  // $('a[href^="#"]').click(function() {
  //   // スクロールの速度
  //   var speed = 400; // ミリ秒
  //   // アンカーの値取得
  //   var href = $(this).attr("href");
  //   // 移動先を取得
  //   var target = $(href == "#" || href == "" ? 'html' : href);
  //   // 移動先を数値で取得
  //   var position = target.offset().top;
  //   // スムーススクロール
  //   $('body,html').animate({
  //     scrollTop: position
  //   }, speed, 'easeInOutQuad');
  //   return false;
  // });

  // Drawer.js
  $('.drawer').drawer({
    nav: 'drawer-nav',
    toggle: 'drawer-toggle',
    overlay: 'drawer-overlay',
    open: 'drawer-open',
    close: 'drawer-close',
    dropdown: 'drawer-dropdown'
  });


  // IntroPic

  var $intropic = Snap('#intropic path');
  var path = [
    // Path1
    'M330,250H0V0h330V250z M62.3,43.3c-23.5,19.5-42.5,34.5-47.5,59s1.5,35,5,60.5s44,50.5,63.5,59.5s39.5,13,87.5,10s58.5-27.5,89.5-47s52.5-52.5,55-72s-10-51.5-31-60.5s-48-27.5-65.5-31.5s-36.9-8.5-60.5-7.5C121.8,15.3,85.8,23.8,62.3,43.3z', //
    // Path2
    'M330,250H0V0h330V250z M93.8,41.5c-25,14.9-54.4,7.9-70.4,44.1c-14.8,33.5-2.5,50,1.1,77.2c4.8,36.8,22.5,53.1,42.1,65c20.2,12.3,46.4,7,97.5,3.8s64.4,1.6,97.5-19.2s37.3-44.7,39.9-65.5c2.7-20.8,22.4-43.1,0.5-69c-15.7-18.6-29.1-28.4-50.6-39.2c-17.1-8.6-46.8-28.2-71.9-27.2C140.6,13.2,121.7,24.9,93.8,41.5z',
    // Path3
    'M330,250H0V0h330V250z M66,39C42.5,53,29.5,60,20,92.5c-9.6,33-3.8,47-0.5,72.5c4.5,34.5,33.5,40,53,49s45.5,19.5,93.5,16.5s61.5-25.5,92.5-45s54-46.5,56.5-66S299,70,278,56c-19-12.7-37-31-55.5-35.5c-17.4-4.2-48.4,0-72,1C114,23,92.3,23.3,66,39z',
    // Path4
    'M330,250H0V0h330V250z M71.2,28.6c-25,14.5-27.5,17.5-43,56.2c-12.5,31.3-12.8,45.6-12,72.5c1.1,37.2,13.6,51.3,34,60.7s47.5,12.1,97.6,9c50.1-3.1,63.1,5.9,97.5-10.8c34.5-16.8,43.5-38.5,52.5-57c11.1-23,22.7-43.8,7.7-71.1C294,67,300.5,46.4,260,27.4c-34.3-16.1-55.7-0.7-86.1,0.7C135.7,30,99.7,12.1,71.2,28.6z'
  ];
  var i = 0;
  var DURATION = 4000;
  var EASING = mina.easeinout;

  function playAnimation() {
    if (i === 0) {
      $intropic.animate({ d: path[0] }, DURATION, EASING, playAnimation);
      i = 1;
    } else if (i === 1) {
      $intropic.animate({ d: path[1] }, DURATION, EASING, playAnimation);
      i = 2;
    } else if (i === 2) {
      $intropic.animate({ d: path[2] }, DURATION, EASING, playAnimation);
      i = 3;
    } else {
      $intropic.animate({ d: path[3] }, DURATION, EASING, playAnimation);
      i = 0;
    }
  }
  playAnimation();
  // 外部サイトは新規タブ
  // $('a[href^="http"]').not('[href^="https"]').not('[href^="http://example.com"]').click(function() {
  //     window.open(this.href, '_blank');
  //     return false;
  // }).addClass('external');

  //ページ上部
  // $('#totop').hide();
  // $(window).bind("scroll", function() {
  //     if ($(this).scrollTop() > 500) {
  //         $('#totop').slideDown();
  //     } else {
  //         $('#totop').slideUp();
  //     }
  //     // フッター固定
  //     scrollHeight = $(document).height(); // ドキュメントの高さ
  //     scrollPosition = $(window).height() + $(window).scrollTop(); // 現在のトップからの位置
  //     footHeight = $("#global_footer").innerHeight(); // フッターの内側の高さ
  //     if (scrollHeight - scrollPosition <= footHeight) { // フッターの高さの位置にはいったら
  //         $('#totop').css({
  //             "position": "absolute",
  //             "bottom": ((footHeight) + 30)
  //         });
  //     } else {
  //         $('#totop').css({
  //             "position": "fixed",
  //             "bottom": "30px"
  //         });
  //     }
  // });

  // 表示ページナビにclass付加
  var activeUrl = location.pathname.split("/")[1];
  navList = $("#global_nav").find("a");

  navList.each(function() {
    if ($(this).attr("href").split("/")[1] == activeUrl) {
      $(this).parent("li").addClass("current");
    };
  });
  // PC tel Off
  // var ua = navigator.userAgent;
  // if(ua.indexOf('iPhone') < 0 && ua.indexOf('Android') < 0){
  //     $('a[href^="tel"]').each(function(){
  //         $(this).contents().unwrap();
  //     });
  // }

  // Image hover
  // $('.img_hover img').hover(function() {
  //     $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
  // }, function() {
  //     $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
  // });

  // Colorbox
  // $(".youtube").colorbox({
  //     iframe: true,
  //     innerWidth: 853,
  //     innerHeight: 480
  // });
  //   $('.colorbox').colorbox({
  //   maxWidth: "90%"
  // });
  // フレームの高さをウィンドウサイズに合わせる。
  // var wHeight = $(window).height();
  // $(window).resize(function() {
  //     $(".slideFrame").height($(window).height());
  // }).resize();

  //スクロール追従メニュー
  // var side = $(".sideMenu");
  // var main = $(".content");

  // var window_min = 0;
  // var window_max = 0;

  // var min_move = main.offset().top;
  // var max_move = main.offset().top + main.height() - side.height() - 2 * parseInt(side.css("top"));
  // var threshold_offset = 50;
  // var window_min = min_move - threshold_offset;
  // var window_max = max_move + side.height() + threshold_offset;

  // function window_scroll() {
  //     if ($(window).scrollTop() >= window_min && $(window).scrollTop() < window_max) {
  //         container_move();
  //     }
  // }
  // $(window).bind("scroll", window_scroll);


  // function container_move() {
  //     if ($(window).scrollTop() >= min_move && $(window).scrollTop() <= max_move) {

  //         var margin_top = $(window).scrollTop() - min_move;
  //         side.animate({
  //             "margin-top": margin_top
  //         }, {
  //             duration: 500,
  //             queue: false
  //         });
  //     } else if ($(window).scrollTop() <= min_move) {
  //         side.css("margin-top", 0);
  //     } else if ($(window).scrollTop() > max_move) {
  //         side.css("margin-top", max_move - min_move + "px");
  //     }
  // }
  // container_move();

  // Slide Toggle Menu
  // $('.globalNav_items').hide();
  // $('.js-globalNav').bind('touchstart', function() {
  //   $('.globalNav_items').slideToggle('slow');
  // });

  // Slide Toggle Nav
  // var isTouch = ('ontouchstart' in window) ? 'touchstart' : 'click';
  //   $('.menuBtn').on(isTouch, function() {
  //       $('.gNav_items').slideToggle('slow');
  //       $(this).toggleClass('open');
  //       return false;
  //     });


  // Slide Toggle Index
  // var tglBtn = $('.js-toggleBtn');
  // var tglPanel = $('.js-togglePanel');
  // tglPanel.hide();
  // tglBtn.bind('touchstart', function() {
  //   $(this).next(tglPanel).slideToggle('slow');
  //   $(this).toggleClass('faqBox_title-close');
  // });

  // Fakeloader
  // $("#fakeloader").fakeLoader({
  //       bgColor: "#000",
  //       spinner: "spinner4"
  //   });

  // Animate CSS

  // function animate($ani, $valPos) {
  //     if ($ani === 'fadeInUp' || $ani === 'fadeInLeft' || $ani === 'fadeInRight') {
  //         $(".ani-" + $ani).css("opacity", "0");
  //     }
  //     $(".ani-" + $ani).each(function() {
  //         var imgPos = $(this).offset().top;
  //         var scroll = $(window).scrollTop();
  //         var windowHeight = $(window).height();
  //         if (scroll > imgPos - windowHeight + windowHeight / $valPos) {
  //             $(this).addClass("animated " + $ani);
  //         } else {
  //             $(this).removeClass("animated " + $ani);
  //         }
  //     });
  // }
  // $(window).on('touchstart scroll', function() {
  //     var timer = false;
  //     if (timer !== false) {
  //         clearTimeout(timer);
  //     }
  //     timer = setTimeout(function() {
  //         animate('fadeInUp', '5');
  //         animate('fadeInLeft', '3');
  //         animate('fadeInRight', '3');
  //         animate('bounce', '2');
  //     }, 100);
  // });

});
